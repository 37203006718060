<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row justify-content-around">
        <div class="col">
          <h5 class="page-header">Manage users</h5>
        </div>
        <div class="col text-end">
          <BaseButton v-if="user.isAdminUser" :classes="'btn-success disabled'" @click="createNewUser()">
            New User
          </BaseButton>
        </div>
      </div>
      <hr class="dotted-spacer" />
      <div class="row pt-4">
        <div class="col">
          <!-- Loading spinner -->
          <div
            v-if="$apollo.loading"
            class="position-fixed end-0 w-100 justify-content-center"
            style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
          >
            <div class="text-center col pt-5 mt-5">
              <BaseSpinner />
              <p class="d-block lead fs-4 mt-5">Loading users</p>
            </div>
          </div>
          <div v-else>
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :sort-options="{ enabled: true }"
              :pagination-options="paginationOptions"
              style-class="vgt-table condensed striped"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'role'">
                  <span v-if="props.row.role === 'ADMIN'" :class="{ 'text-muted': props.row.hiddenInList }">Admin</span>
                  <span v-else-if="props.row.role === 'SYSADMIN'" :class="{ 'text-muted': props.row.hiddenInList }"
                    >System Admin</span
                  >
                  <span v-else-if="props.row.role === 'TEAM'" :class="{ 'text-muted': props.row.hiddenInList }"
                    >Team Member</span
                  >
                  <span v-else-if="props.row.role === 'VIEWER'" :class="{ 'text-muted': props.row.hiddenInList }"
                    >Viewer</span
                  >
                  <span v-else :class="{ 'text-muted': props.row.hiddenInList }">-</span>
                </span>

                <span v-else-if="props.column.field == 'email'">
                  <a class="link-primary" :href="'mailto:' + props.row.email">{{ props.row.email }}</a>
                </span>

                <!-- name -->
                <span v-else-if="props.column.field == 'name'">
                  <span :class="{ 'text-muted': props.row.hiddenInList }">{{ props.row.name }}</span>
                </span>

                <span v-else-if="props.column.field == 'selfApprove'">
                  <BaseIcon v-if="props.row.selfApprove === true" class="text-muted text-lg" :name="'check'" />
                  <BaseIcon
                    v-else-if="['USER', 'VIEWER'].includes(props.row.role)"
                    :name="'minus'"
                    class="text-muted text-lg"
                  />
                  <BaseIcon v-else :name="'times'" class="text-muted text-lg" />
                </span>

                <!-- Custom Actions section -->
                <div
                  v-else-if="props.column.field == 'after'"
                  class="capitalize-first text-xl w-100 flex text-end"
                  :title="props.row.id"
                >
                  <BaseButton
                    v-if="user.isAdminUser"
                    class="inline text-primary cursor-pointer mr-3"
                    :classes="'px-2'"
                    @click="tableOnEditItem(props.row.id)"
                  >
                    <BaseIcon :name="['far', 'edit']" />
                  </BaseButton>
                  <BaseButton
                    v-if="user.isAdminUser && !props.row.hiddenInList"
                    class="inline text-primary cursor-pointer mr-3"
                    :classes="'px-2'"
                    title="Hide user from the list"
                    @click="hideUser(props.row.id)"
                  >
                    <BaseIcon :name="['far', 'times-circle']" />
                  </BaseButton>
                  <BaseButton
                    v-else-if="user.isAdminUser"
                    class="inline text-primary cursor-pointer mr-3"
                    :classes="'px-2'"
                    title="Show user in the list"
                    @click="hideUser(props.row.id, false)"
                  >
                    <BaseIcon :name="['far', 'check-circle']" />
                  </BaseButton>
                </div>

                <!-- standard column -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@layouts/main";
import { LocalGetSelf, GetUsers, ShowHideUser } from "@gql/user";

import { format, parseISO } from "date-fns";
import { reject } from "lodash";

export default {
  page: {
    title: "Users",
    meta: [{ name: "description", content: "Users" }],
  },
  components: { Layout },
  data() {
    return {
      loading: true,
      showHidden: false,
      columns: [
        {
          label: "Name",
          field: "name",
          filterOptions: {
            // styleClass: '', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            // placeholder: "Tag name", // placeholder for filter input
            // filterValue: 'Test', // initial populated value for this filter
            // filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, // custom filter function that takes two vars: data, filterString: should return true if data matches the filterString, otherwise false
            // trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: "Team role",
          field: "role",
          filterOptions: {
            enabled: true,
            filterDropdownItems: ["ADMIN", "TEAM", "USER", "VIEWER", "SYSADMIN"],
          },
        },
        {
          label: "Self approve",
          field: "selfApprove",
          type: "boolean",
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Created",
          field: "createdAtFormatted",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "do MMM yyyy",
        },
        {
          label: "",
          field: "after",
        },
      ],
      paginationOptions: {
        enabled: true,
        perPage: 25,
        perPageDropdown: [10, 25, 50, 100],
      },
    };
  },
  apollo: {
    user: LocalGetSelf,
    users: {
      query: GetUsers,
      variables() {
        return {
          where: {
            company: { id: { equals: this.user.company.id } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update: (data) => data.users,
      result: function ({ data }) {
        this.loading = false;
        this.showHidden = true;
      },
      error: (err) => this.$log.error(err),
      fetchPolicy: "cache-and-network",
    },
  },
  computed: {
    rows: function () {
      if (!this.users) return [];
      const showHidden = this.showHidden;
      return (
        this.users
          .map((user) => ({
            ...user,
            createdAtFormatted: format(parseISO(user.createdAt), "yyyy-MM-dd"),
            role: user.role.name,
            company: user.company.name,
          }))
          // Show users if they're not hiden or if the user is an admin
          .filter(function (user) {
            return !user.hiddenInList || showHidden;
          })
      );
    },
  },
  methods: {
    refreshTable: function () {
      this.loading = true;
      this.$apollo.queries.users.refresh();
    },
    tableOnEditItem: function (userId) {
      if (this.user && this.user.isAdminUser) {
        this.$log.debug("tableOnEditItem - going to users.edit", { id: userId });
        this.$router.push({ name: "users.edit", params: { id: userId } });
      }
    },
    createNewUser: function () {
      if (this.user && this.user.isAdminUser) {
        this.$log.debug("createNewUser - going to users.new");
        this.$router.push({ name: "users.new" });
      }
    },
    hideUser: function (userId, hide = true) {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: ShowHideUser,
          variables: {
            id: userId,
            hiddenInList: hide,
          },
          // Update the cache with the result
          update: (store, { data: { updateUser } }) => {},
          error(err) {
            this.$log.error("hideUser - API Error", err);
          },
        })
        .then((data) => {
          // Hide row from Table
          if (hide === true && !this.showHidden) {
            this.users = reject(this.users, (user) => user.id === userId);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$log.error("hideUser - Apollo Error", err);
          this.loading = false;
        });
    },
  },
};
</script>
